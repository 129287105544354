import ExportJsonExcel from 'js-export-excel';
import Papa from "papaparse";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BookingCategory } from '../Helper';
import { convertStripeAmount } from './ReportCloverOrderUtil';
import { format } from 'date-fns';

const comparisonDate = new Date('2024-08-20T00:00:00Z');

export function getGratuity(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            const gratuity = parseFloat(otherInfo.gratuity) || 0;
            if (gratuity === data.amount) {
                return 0;
            }
            return (gratuity / 100).toFixed(2);
        } catch (error) {
            return 0;
        }
    }
    return 0;
}
export function getSaleTax(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            const saletax = parseFloat(otherInfo.saletax) || 0;
            return (saletax).toFixed(2);
        } catch (error) {
            return "0.00";
        }
    }
    return "0.00";
}

export function checkIfFromCardReaderPos(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            if (otherInfo && otherInfo.isFromCardReader) {
                return true
            }
        } catch (error) {
            return false
        }
    }
    return false;
}
export function getChargeCardAmount(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            const chargeCard = parseFloat(otherInfo.chargeCard) || 0;
            return (chargeCard / 100).toFixed(2);
        } catch (error) {
            return 0.00;
        }
    }
    return 0.00;
}
export function getMinimumspend(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            const minimumspend = parseFloat(otherInfo.minimumspend) || 0;
            return (minimumspend / 100).toFixed(2);
        } catch (error) {
            return 0.00;
        }
    }
    return 0.00;
}

export function getPointspend(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            const drivingfeetbalance = parseFloat(otherInfo.drivingfeetbalance) || 0;
            return (drivingfeetbalance / 100).toFixed(2);
        } catch (error) {
            return 0.00;
        }
    }
    return 0.00;
}

export function getTotal(data) {
    if (data && data.metadata && data.metadata.otherInfo) {
        try {
            const otherInfo = JSON.parse(data.metadata.otherInfo);
            const gratuity = parseFloat(otherInfo.gratuity) || 0;
            const saletax = parseFloat(otherInfo.saletax) || 0;
            const isFromCardReader = otherInfo.isFromCardReader || false;

            if (isFromCardReader) {
                // If amount includes gratuity, return amount as is
                return (data.amount / 100).toFixed(2);
            }

            // Otherwise, add gratuity to the amount
            const total = data.amount + gratuity + (saletax * 100);
            return (total / 100).toFixed(2);
        } catch (error) {
            // Handle JSON parsing error
            return (data.amount / 100).toFixed(2);
        }
    }
    // If metadata or otherInfo is missing, return amount as is
    return (data.amount / 100).toFixed(2);
}

export function generateExelData(dataArr, exportSelectedOption, allUsers) {

    const formattedData = dataArr && dataArr.map((item) => {
        // Calculate the total price for all products in the item
        const user = allUsers.filter(t => t.email == item.email)[0];
        let userName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);

        if (item.cloverRecord) {
            let lineArr = item.lineItems && item.lineItems.elements ? item.lineItems.elements : []
            return {
                'Id': item.id,
                'Order Date': format(new Date(item.createdTime), 'dd-MMM-yyyy h:mm a'),
                'Email': item?.user?.email || '',
                'Order By': item?.note,
                "Products": lineArr.map(product => (
                    `${product.name} $${(product.price / 100).toFixed(2)}`
                )).join(',\n'),
                'Product Total': convertStripeAmount(item.total).toFixed(2),  // Add the new Product Total field
                'Gratuity': 'N/A',
                'SaleTax': 'N/A',
                'Minimumspend': '0',
                'Total': convertStripeAmount(item.total).toFixed(2),
            };
        }
        else if (item.metadata.productInfo) {
            const products = Array.isArray(item.products) ? item.products : [];
            // Calculate the total price for all products in the item
            const productTotal = products.reduce((total, product) => {
                return total + product.price * product.quantity;
            }, 0);
            const total = (intentCreatedAt < comparisonDate) ? (item.amount / 100).toFixed(2) : getTotal(item);
            if (item.guestName && item.guestName != "") {
                userName = `${item.guestName}(Guest)`;
            }
            return {
                'Id': item.id,
                'Order Date': new Date(item.intentCreatedAt * 1000).toLocaleDateString(),
                'Email': item.email,
                'Order By': userName,
                "Products": products.map(product => (
                    `${product.quantity} x ${product.productName} / $${(product.price / 100).toFixed(2)}`
                )).join(',\n'),  // Use \n to separate products with a newline
                'Product Total': `${(productTotal / 100).toFixed(2)}`,  // Add the new Product Total field
                'Gratuity': getGratuity(item),
                'SaleTax': getSaleTax(item),
                'Minimumspend': getMinimumspend(item),
                'Total': total,
            };
        } else if (item.metadata && item.metadata.isPaymentFrom && item.metadata.isPaymentFrom == BookingCategory.Paid_Event_RSVP) {
            return {
                'Id': item.id,
                'Order Date': new Date(item.intentCreatedAt * 1000).toLocaleDateString(),
                'Email': item.email,
                'Order By': userName,
                "Products": `${BookingCategory.Paid_Event_RSVP}`,
                'Product Total': `${(item.amount / 100).toFixed(2)}`,  // Add the new Product Total field
                'Gratuity': 0,
                'SaleTax': 0,
                'Minimumspend': getMinimumspend(item),
                'Total': `${(item.amount / 100).toFixed(2)}`,
            };
        } else if (item.metadata && item.metadata.isPaymentFrom && item.metadata.isPaymentFrom == BookingCategory.Vehicle_Booking) {
            return {
                'Id': item.id,
                'Order Date': new Date(item.intentCreatedAt * 1000).toLocaleDateString(),
                'Email': item.email,
                'Order By': userName,
                "Products": `${BookingCategory.Vehicle_Booking}`,
                'Product Total': `${(item.amount / 100).toFixed(2)}`,  // Add the new Product Total field
                'Gratuity': 0,
                'SaleTax': 0,
                'Minimumspend': getMinimumspend(item),
                'Total': `${(item.amount / 100).toFixed(2)}`,
            };
        }
        return {
            'Id': '',
            'Order Date': '',
            'Email': '',
            'Order By': '',
            "Products": ``,
            'Product Total': '0',  // Add the new Product Total field
            'Gratuity': 0,
            'SaleTax': 0,
            'Minimumspend': 0,
            'Total': 0,
        };
    });

    const option = {
        fileName: 'orders',
        datas: [
            {
                sheetData: formattedData,
                sheetName: 'Orders',
                sheetFilter: ['Id', 'Order Date', 'Email', 'Order By', 'Products', 'Product Total', 'Gratuity', 'SaleTax', 'Minimumspend', 'Total'],
                sheetHeader: ['Id', 'Order Date', 'Email', 'Order By', 'Products', 'Product Total', 'Gratuity', 'SaleTax', 'Minimumspend', 'Total'],
                columnWidths: [10, 8, 8, 10, 30, 8, 8, 8, 8, 8]
            },
        ],
    };

    if (exportSelectedOption === 'Excel') {
        const toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    } else if (exportSelectedOption === 'CSV') {
        exportToCSV(formattedData, 'orders');
    } else if (exportSelectedOption === 'PDF') {
        exportToPDF(formattedData, 'orders');
    } else if (exportSelectedOption === 'Print') {
        printData(formattedData);
    }
}

export function exportToCSV(data, fileName) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportToPDF(data, fileName) {
    const doc = new jsPDF('landscape');  // Use landscape mode for more horizontal space
    const tableColumn = ["Id", "Order Date", "Email", "Order By", "Products", "Product Total", "Gratuity", "SaleTax", "Minimumspend", "Total"];
    const tableRows = [];

    data.forEach(item => {
        // Split products into multiple lines
        const products = item.Products.split(',\n').map(productLine => {
            return doc.splitTextToSize(productLine, 80); // Adjust the width for landscape mode
        }).flat();

        // Create a row for each item, with products on separate lines
        const row = [
            item.Id,
            item['Order Date'],
            item.Email,
            item['Order By'],
            products.join('\n'),
            `$${item['Product Total']}`,
            item.Gratuity,
            item.SaleTax,
            item.Minimumspend,
            item.Total
        ];

        tableRows.push(row);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,  // Adjust as needed
        styles: {
            cellPadding: 2,
            fontSize: 8,
            overflow: 'linebreak',
        },
        columnStyles: {
            0: { cellWidth: 20 },  // Id
            1: { cellWidth: 20 },  // Order Date
            2: { cellWidth: 30 },  // Email
            3: { cellWidth: 30 },  // Order By
            4: { cellWidth: 70 },  // Products
            5: { cellWidth: 20 },  // Product Total
            // 6: { cellWidth: 20 },  // Gratuity
            // 7: { cellWidth: 20 },  // SaleTax
            // 8: { cellWidth: 20 },  // Minimumspend
            // 9: { cellWidth: 20 },  // Total
        },
        theme: 'striped',  // Optional: adds a striped effect to the rows
        headStyles: { fillColor: [22, 160, 133] },  // Optional: change header color
    });

    doc.save(`${fileName}.pdf`);
}

function printData(data) {
    const printWindow = window.open('', '_blank');
    const htmlContent = `
        <html>
            <head>
                <title>Print Orders</title>
                <style>
                    @page {
                        size: A4 landscape; /* A4 size with landscape orientation */
                        margin: 10mm;
                    }
                    body {
                        font-family: Arial, sans-serif;
                        margin: 2px;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        table-layout: fixed; /* Fixed layout to respect percentage widths */
                    }
                    th, td {
                        border: 1px solid black;
                        padding: 2px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                        font-size: 12px;
                    }
                    td {
                        font-size: 10px;
                    }
                    /* Set column widths based on percentage */
                    th:nth-child(1), td:nth-child(1) { width: 15%; }  /* Id */
                    th:nth-child(2), td:nth-child(2) { width: 5%; } /* Order Date */
                    th:nth-child(3), td:nth-child(3) { width: 20%; } /* Email */
                    th:nth-child(4), td:nth-child(4) { width: 15%; } /* Order By */
                    th:nth-child(5), td:nth-child(5) { width: 30%; } /* Products */
                    th:nth-child(6), td:nth-child(6) { width: 8%; } /* Product Total */
                    th:nth-child(7), td:nth-child(7) { width: 5%; }  /* Gratuity */
                    th:nth-child(8), td:nth-child(8) { width: 5%; }  /* SaleTax */
                    th:nth-child(9), td:nth-child(9) { width: 5%; }  /* Minimumspend */
                    th:nth-child(10), td:nth-child(10) { width: 5%; } /* Total */
                </style>
            </head>
            <body>
                <h1>Orders</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Order Date</th>
                            <th>Email</th>
                            <th>Order By</th>
                            <th>Products</th>
                            <th>Product Total</th>
                            <th>Gratuity</th>
                            <th>SaleTax</th>
                            <th>Minimum spend</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${data.map(item => `
                            <tr>
                                <td>${item.Id}</td>
                                <td>${item['Order Date']}</td>
                                <td>${item.Email}</td>
                                <td>${item['Order By']}</td>
                                <td>${item.Products.split(',\n').join('<br/>')}</td>
                                <td>$${item['Product Total']}</td>
                                <td>${item.Gratuity}</td>
                                <td>${item.SaleTax}</td>
                                <td>${item.Minimumspend}</td>
                                <td>${item.Total}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
            </body>
        </html>
    `;

    printWindow.document.write(htmlContent);
    printWindow.document.close();

    // Give some time for the content to load before triggering the print
    setTimeout(() => {
        printWindow.print();
    }, 500);
}
