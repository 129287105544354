import ExportJsonExcel from 'js-export-excel';
import Papa from "papaparse";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import moment from 'moment';

const tempColumn = [
    'IsFrom',
    'Total',
    'Subtotal',
    'Invoice Tax',
    'Applied balance',
    'Charge Amount',
    'Status',
    'Description',
    'Date Created',
    'Line Item',
    'Other Info'
]

export function generateExelData(dataArr, exportSelectedOption, allUsers) {


    const formattedData = dataArr && dataArr.map((invoice) => {
        if (invoice) {
            return {
                'IsFrom': invoice.isFrom || '',
                'Total': invoice.total || 0,
                'Subtotal': invoice.invoiceSubtotal || 0,
                'Invoice Tax': invoice.invoiceTax,
                'Applied balance': invoice.appliedBalance ? invoice.appliedBalance : Math.abs(invoice.startingBalance) || 0,
                'Charge Amount': invoice.chargeAmount || '',
                'Status': invoice.status.toUpperCase(),
                'Description': invoice.description || "",
                'Date Created': invoice.created || '',
                'Line Item': JSON.stringify(invoice.line_items),
                'Other Info': (invoice && invoice.metadata && invoice.metadata.isPaymentFrom ? JSON.stringify(invoice.metadata) : '')
            };
        }
        return {
            'IsFrom': '',
            'Total': '',
            'Subtotal': '',
            'Invoice Tax': '',
            'Applied balance': '',
            'Charge Amount': '',
            'Status': '',
            'Description': '',
            'Date Created': '',
            'Line Item': '',
            'Other Info': ''
        };
    });

    const option = {
        fileName: 'reports-userpurchase',
        datas: [
            {
                sheetData: formattedData,  // Assume `formattedData` is your dataset
                sheetName: 'Orders',
                sheetFilter: tempColumn,
                sheetHeader: tempColumn,
                columnWidths: tempColumn.map(col => parseInt(col.width, 10) / 20)  // Adjust if needed for your export
            },
        ],
    };


    if (exportSelectedOption === 'Excel') {
        const toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    } else if (exportSelectedOption === 'CSV') {
        exportToCSV(formattedData, 'reports-userpurchase');
    } else if (exportSelectedOption === 'PDF') {
        exportToPDF(dataArr, allUsers, 'reports-userpurchase');
    } else if (exportSelectedOption === 'Print') {
        //printData(formattedData);
    }
}

export function exportToCSV(data, fileName) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportToPDF(data, allUsers, fileName) {
    const doc = new jsPDF('landscape');  // Use landscape mode for more horizontal space
    const tableColumn = tempColumn;
    const tableRows = [];



    data.forEach(invoice => {
        let other = (invoice && invoice.metadata && invoice.metadata.isPaymentFrom ? JSON.stringify(invoice.metadata) : '')
        const row = [
            invoice.isFrom || '',
            invoice.total || 0,
            invoice.invoiceSubtotal || 0,
            invoice.invoiceTax,
            invoice.appliedBalance ? invoice.appliedBalance : Math.abs(invoice.startingBalance) || 0,
            invoice.chargeAmount || '',
            invoice.status.toUpperCase(),
            invoice.description || "",
            invoice.created || '',
            JSON.stringify(invoice.line_items),
            other,
        ];
        tableRows.push(row);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 1.5,  // Adjust as needed
        styles: {
            cellPadding: 2,
            fontSize: 6,
            overflow: 'linebreak',
        },
        columnStyles: {
            0: { cellWidth: 20 },
            1: { cellWidth: 20 },
            2: { cellWidth: 20 },
            3: { cellWidth: 16 },
            4: { cellWidth: 20 },
            5: { cellWidth: 20 },
            6: { cellWidth: 20 },
            7: { cellWidth: 28 },
            8: { cellWidth: 28 },
            9: { cellWidth: 36 },
            10: { cellWidth: 36 },
        },
        theme: 'striped',  // Optional: adds a striped effect to the rows
        headStyles: { fillColor: [22, 160, 133] },  // Optional: change header color
    });

    doc.save(`${fileName}.pdf`);
}