import {
    Label,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands
} from "@fluentui/react-components";
import { Tag, TagGroup } from "@fluentui/react-tags";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Base_Url, auth, db } from "./services/firebase";
import { CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, FilterSVG, NodataInfoSVG, RadioSelected, RadioUnSelected } from "./static/svgs";
import userDataService from "./services/userDataService";
import CustomSearchInput2 from "./components/CustomSearchInput2";

// Update columns to match the charges API response
const columns = [
    { columnKey: "isfrom", label: "IsFrom", width: '100px' },
    { columnKey: "total", label: "Total", width: '80px' },
    { columnKey: "invoicesubtotal", label: "Subtotal", width: '80px' },
    { columnKey: "invoiceTax", label: "Invoice Tax", width: '80px' },
    { columnKey: "appliedbalance", label: "Applied balance", width: '100px' },
    { columnKey: "chargeamount", label: "Charge Amount", width: '100px' },
    { columnKey: "status", label: "Status", width: '80px' },
    { columnKey: "description", label: "Description", width: '180px' },
    { columnKey: "created", label: "Date Created", width: '150px' },
    { columnKey: "InvoiceLineItem", label: "Line Item", width: '400px' },
    { columnKey: "OtherInfo", label: "Other Info", width: '300px' },
];

function SixMonthBillingCalculation() {
    const styles = useStyles();
    const [allCharges, setAllCharges] = useState([]);
    const [finalCalculation, setFinalCalculation] = useState(null);
    const [currentUserId, setUserId] = useState(null); // State to hold Stripe Customer ID
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [visibleTags, setVisibleTags] = useState([]);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dateTagRef = useRef(null);
    const firstTagRef = useRef(null);
    const [dateTags, setDateTags] = useState([]);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [members, setMembers] = useState([
        { id: 1, name: 'John', selected: false },
        { id: 2, name: 'Jane', selected: false },
        { id: 3, name: 'Doe', selected: false }
    ]);
    const [dateArray, setDateArray] = useState([]);

    const [searchMembers, setSearchMembers] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    useEffect(() => {
        fetchUserList()
    }, []);

    const fetchUserList = async () => {
        try {
            if (!!user) {
                const usersQuery = await userDataService.getAllUser();
                let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setAllUsers(allUserList);
                const transformedArray = allUserList.map(obj => ({
                    id: obj.id,
                    name: `${obj.firstName} ${obj.lastName}`.toLocaleLowerCase(),
                    displayname: `${obj.firstName} ${obj.lastName}`,
                    email: obj.email,
                    selected: false,
                    lastBillingCalculationDate: obj.lastBillingCalculationDate.toDate() || new Date()
                }));
                setMembers(transformedArray)
            }
        } catch (err) {
            console.error(err);
        }
    };

    function formatMetadataTransaction(transaction) {
        try {
            if (transaction.metadata?.isPaymentFrom === "Paid_Event_RSVP") {
                const eventInfo = JSON.parse(transaction.metadata.eventInfo); // Parse eventInfo
                const lineItems = eventInfo.map(item => ({
                    name: item.name, // Use name field from eventInfo
                    qty: 1, // Default quantity is 1
                    amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                }));
                return {
                    total: (transaction.amount / 100).toFixed(2),
                    invoiceSubtotal: (transaction.amount / 100).toFixed(2),
                    invoiceTax: 0,
                    appliedBalance: (transaction.amount / 100).toFixed(2),
                    chargeAmount: 0,
                    status: transaction.status,
                    created: new Date(transaction.createdAt._seconds * 1000).toLocaleString(), // Convert timestamp to human-readable date
                    description: transaction.description,
                    isFrom: 'LOCAL',
                    line_items: lineItems, // Use formatted line items
                    metadata: transaction.metadata
                };
            } else if (transaction.metadata?.isPaymentFrom === "Purchase_Product") {
                const productInfo = JSON.parse(transaction.metadata.productInfo); // Parse productInfo
                const lineItems = productInfo.map(item => ({
                    name: item.id, // Use id field as name (or adjust if product names are available elsewhere)
                    qty: item.quantity, // Use the quantity field from productInfo
                    amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                }));
                return {
                    total: (transaction.amount / 100).toFixed(2),
                    invoiceSubtotal: (transaction.amount / 100).toFixed(2),
                    invoiceTax: 0,
                    appliedBalance: 0,
                    chargeAmount: (transaction.amount / 100).toFixed(2),
                    status: transaction.status,
                    created: new Date(transaction.createdAt._seconds * 1000).toLocaleString(),// Convert timestamp to human-readable date
                    description: transaction.description,
                    isFrom: 'LOCAL',
                    line_items: lineItems, // Use formatted line items
                    metadata: transaction.metadata
                };
            } else if (transaction.metadata?.isPaymentFrom === "Vehicle_Booking") {
                const rentalInfo = JSON.parse(transaction.metadata.rentalInfo); // Parse rentalInfo
                const lineItems = rentalInfo.map(item => ({
                    name: item.name, // Use name field from rentalInfo
                    qty: 1, // Default quantity is 1
                    amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                }));
                return {
                    total: (transaction.amount / 100).toFixed(2),
                    invoiceSubtotal: (transaction.amount / 100).toFixed(2),
                    invoiceTax: 0,
                    appliedBalance: 0,
                    chargeAmount: (transaction.amount / 100).toFixed(2),
                    status: transaction.status,
                    created: new Date(transaction.createdAt._seconds * 1000).toLocaleString(), // Convert timestamp to human-readable date
                    description: transaction.description,
                    isFrom: 'LOCAL',
                    line_items: lineItems, // Use formatted line items
                    metadata: transaction.metadata
                };
            }
        } catch (e) {
            console.error("Invalid JSON in metadata:", transaction.metadata);
            return null; // Skip if parsing fails
        }
        return null; // Return null if none of the conditions match
    }

    const fetchCharges = () => {
        setIsLoading(true)
        const config = {
            method: 'get',
            // url: `${Base_Url}/getUserStripePurchaseHistory`,
            // url: `${Base_Url}/getUserSixMonthBillingHistory`,
            url: `http://127.0.0.1:5001/warehouse-prod-40386/us-central1/getUserSixMonthBillingHistory`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user.accessToken}`,
                'Accept': "application/json"
            },
            params: {
                userId: "id",
            }
        };
        axios.request(config)
            .then((response) => {
                debugger
                setIsLoading(false)
                if (response.status && response.data && response.data.transactions) {

                    //Date Range
                    if (response.data.daterange) {
                        let daterange = response.data.daterange
                        const fromDate = new Date(daterange.fromDate * 1000);
                        const toDate = new Date(daterange.toDate * 1000);
                        let startDateOnly = fromDate.toDateString('en-GB'); // 'en-GB' format: dd/mm/yyyy
                        let endDateOnly = toDate.toDateString('en-GB'); // 'en-GB' format: dd/mm/yyyy
                        let tagStr = `${startDateOnly} - ${endDateOnly}`
                        setDateTags([{ value: tagStr, children: tagStr }])
                    }

                    //Final total
                    if (response.data.final) {
                        setFinalCalculation(response.data.final)
                    }

                    //Display data
                    const formattedTransactions = (response.data.transactions || [])
                        .filter((transaction) => {
                            // Exclude records with specific billing reasons
                            if (
                                transaction.invoice?.billing_reason === "subscription_create" ||
                                transaction.invoice?.billing_reason === "subscription_cycle"
                            ) {
                                return false; // Exclude this transaction
                            }
                            return true; // Include all other transactions
                        }).map((transaction) => {
                            if (transaction.isFrom === 'CLOVER') {
                                return {
                                    total: (transaction.total / 100).toFixed(2),
                                    invoiceSubtotal: (transaction.total / 100).toFixed(2),
                                    invoiceTax: 0,
                                    appliedBalance: 0,
                                    chargeAmount: (transaction.total / 100).toFixed(2),
                                    status: transaction.paymentState,
                                    created: new Date(transaction.createdTime).toLocaleString(), // Convert timestamp to human-readable date
                                    description: transaction.note,
                                    isFrom: 'Clover',
                                    line_items: transaction.lineItems?.elements
                                        ? transaction.lineItems.elements.map((item) => ({
                                            name: item.name, // Use the name field from each item
                                            qty: 1, // Quantity is not available, assuming 1 as default
                                            amount: (item.price / 100).toFixed(2), // Convert price from cents to dollars
                                        }))
                                        : [], // Fallback to empty array if no line_items
                                };
                            } else if (transaction.isFrom === 'LOCAL') {
                                return formatMetadataTransaction(transaction);
                            } else {
                                let invoice = transaction.invoice || {};
                                let charge = transaction.charge || {};
                                let createdDt = invoice.created
                                    ? new Date(invoice.created * 1000).toLocaleString()
                                    : charge.created
                                        ? new Date(charge.created * 1000).toLocaleString()
                                        : '';
                                return {
                                    total: invoice && invoice.total ? convertToAmount(invoice.total) : convertToAmount(charge.amount) || 0,
                                    invoiceSubtotal: invoice && invoice.subtotal ? convertToAmount(invoice.subtotal) : 0,
                                    invoiceTax: invoice && invoice.tax ? convertToAmount(invoice.tax) : 0,
                                    appliedBalance: invoice && invoice.pre_payment_credit_notes_amount
                                        ? convertToAmount(invoice.pre_payment_credit_notes_amount)
                                        : 0,
                                    startingBalance: invoice && invoice.starting_balance ? convertToAmount(invoice.starting_balance) : 0,
                                    chargeAmount: convertToAmount(charge.amount) || 0,
                                    status: invoice && invoice.status ? invoice.status : charge.status || '',
                                    created: createdDt, // Convert timestamp to human-readable date
                                    description: invoice?.description || charge?.description || '',
                                    isFrom: 'Stripe',
                                    line_items: invoice.line_items
                                        ? invoice.line_items.map((item) => ({
                                            name: item.description, // Use description as item name
                                            qty: item.quantity, // Quantity
                                            amount: (item.amount_paid / 100).toFixed(2), // Convert amount to dollars
                                        }))
                                        : [], // Fallback to empty array if no line_items
                                    metadata: invoice.metadata || null,
                                };
                            }
                        });
                    formattedTransactions.sort((a, b) => {
                        const dateA = new Date(a.created).getTime();
                        const dateB = new Date(b.created).getTime();
                        return dateA - dateB; // For ascending order (oldest first)
                    });
                    setAllCharges(formattedTransactions)
                } else {
                    setAllCharges([])
                }
            })
            .catch((err) => {
                setIsLoading(false)
                console.error(err);
                alert("Error fetching charge data.", err);
            });
    }

    const toggleSelect = (id) => {
        setMembers((prevMembers) =>
            prevMembers.map((member) =>
                member.id === id
                    ? { ...member, selected: true } // Select the clicked member
                    : { ...member, selected: false } // Deselect all other members
            )
        );
    };
    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        setMembers(prevMembers =>
            prevMembers.map(member =>
                member.id === value ? { ...member, selected: false } : member
            )
        );
    };
    const handleApplyMember = () => {
        // Update visible tags
        const selectedMembers = members.filter(member => member.selected);
        const updatedTags = selectedMembers.map(member => ({
            value: member.id,
            children: member.displayname
        }));
        setVisibleTags(updatedTags);
        // Close the popup
        setPopupVisible(false);

        // Use the first selected member's created date
        const createdDate = new Date(selectedMembers[0].lastBillingCalculationDate); // Assuming createdDate is available in `member`
        const firstHalfStart = new Date(createdDate);
        const firstHalfEnd = new Date(createdDate);
        firstHalfEnd.setMonth(firstHalfEnd.getMonth() + 5); // First 6 months (5 + current month)
        firstHalfEnd.setDate(0); // Set to last day of the month

        const secondHalfStart = new Date(firstHalfEnd);
        secondHalfStart.setDate(secondHalfStart.getDate() + 1); // Start from next day after first half
        const secondHalfEnd = new Date(secondHalfStart);
        secondHalfEnd.setMonth(secondHalfEnd.getMonth() + 5); // Next 6 months
        secondHalfEnd.setDate(0); // Set to last day of the month

        // Format date ranges
        const firstHalf = `${firstHalfStart.toLocaleDateString()} – ${firstHalfEnd.toLocaleDateString()}`;
        const secondHalf = `${secondHalfStart.toLocaleDateString()} – ${secondHalfEnd.toLocaleDateString()}`;
        setDateArray([
            { value: "1st_half", children: firstHalf },
            { value: "2nd_half", children: secondHalf },
        ]);

    };
    const handleClear = () => {
        setSearchTerm('');
    };

    const handleSearchMember = event => {
        const value = event.target.value;
        setSearchMembers(value);
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
        setPopupVisibleExport(false)
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(true)
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };
    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };
    function onGenerateExportClick(from) {
        const dataArr = allCharges;
        if (dataArr.length > 0) {
            if (from == 2) {
            } else {
                //generateExelData(dataArr, exportSelectedOption, [])
            }
        }
    }

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };
    const handleCancel = () => {
        // Close the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };
    const handleDateApply = () => {
        let startDate = null;
        let endDate = null;
    }
    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={styles.breakFlex}>
                    {/* <Button shape="circular" appearance="primary" onClick={() => navigate(-1)}>Back</Button> */}
                </div>
                <div className={"d-flex justify-space-between"} style={{ marginTop: '10px' }}>
                    <div className={"d-flex"}>
                        <div style={{ marginTop: '8px' }}>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>Customer name</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>6-Month Period</span>
                            <DownArowSVG />
                        </div>
                    </div>

                    <div className={"d-flex"}>
                        {/* <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div> */}
                    </div>
                </div>

                <div style={{ marginTop: '10px' }}>
                    {dateTags.length !== 0 && (
                        <TagGroup role="list" aria-label="Dismiss example">
                            {dateTags.map((tag, index) => (
                                <Tag
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? dateTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>
                <div style={{ marginTop: '10px' }}>
                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className="table-layout3">
                    <Table aria-label="Table with cell actions"> {/* Set min-width here */}
                        <TableHeader className="list-row2">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey} style={{ width: column.width }}> {/* Set width here */}
                                        <span className="listHeaderText">{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {allCharges.length > 0 && (
                            <TableBody>
                                {allCharges.map((invoice) => (
                                    <TableRow key={invoice.created}>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.isFrom || ''}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.total || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.invoiceSubtotal || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.invoiceTax || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.appliedBalance ? invoice.appliedBalance : Math.abs(invoice.startingBalance) || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.chargeAmount || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.status.toUpperCase() || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.description || ''}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.created || ''}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <LineItemList lineItems={invoice.line_items} />
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {
                            isLoading || allCharges.length > 0 ? <div /> :
                                // <TableBody>
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Report Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please select user, then click 'GENERATE REPORT' to view, export, and print the data.
                                    </div>
                                </div>
                            // </TableBody>
                        }
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                </div>
                {popupVisible && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <CustomSearchInput2
                            value={searchMembers}
                            onChange={handleSearchMember}
                            onClick={handleClear}
                        />
                        <div className="popupMemberList">
                            {members
                                .filter(member => member.name.toLowerCase().includes(searchMembers))
                                .map((member) => (
                                    <div key={member.id}>
                                        <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                            <div
                                                onClick={() => toggleSelect(member.id)}
                                                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                            >
                                                {member.selected ? (
                                                    <RadioSelected />  // Your checked SVG
                                                ) : (
                                                    <RadioUnSelected />  // Your unchecked SVG
                                                )}
                                                <div style={{ marginLeft: '8px' }}>{member.displayname}</div>
                                            </div>
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            {/* <button className="borderButton" onClick={handleCancelMemberFilter}>Cancel</button> */}
                            <button className="fillButton" onClick={handleApplyMember}>APPLY</button>
                        </div>
                    </div>
                )}

                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y + 10, left: popupPosition.x - 50, height: 170 }}>
                        {dateArray.map((tag, index) => (
                            <div
                                key={tag.children}
                                className={`todayBtn${selectedOption === tag.children ? " selected" : ""}`}
                                onClick={() => handleOptionClick(tag.children)}
                            >
                                {tag.children}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancel}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default SixMonthBillingCalculation;

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },
    popupmain: {
        width: '256px',
        height: '280px',
        position: 'relative',
        top: '124px',
        borderRadius: '10px',
        border: '1px solid #F4F4F4',
        background: '#FFF',
        boxShadow: '1px 1px 4px 0px rgba(89, 89, 89, 0.25)',
        padding: '16px',
        overflowY: 'auto',
    },
    popupMemberList: {
        paddingTop: '10px',
        maxHeight: '266px',
        overflowY: 'auto'
    },
    totalAmount: {
        marginTop: "16px",
        fontWeight: "bold"
    }
});



const styles = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};
const convertToAmount = (amount) => {
    return amount ? (amount / 100).toFixed(2) : null
}
const LineItemList = ({ lineItems }) => {
    if (lineItems && lineItems.length > 0) {
        return (
            <div>
                {lineItems.map((item, index) => (
                    <div key={index}>
                        <div style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{item.name}</div>
                                <div style={styles.productName2}>${item.amount}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return <div>-</div>;
    }
};