import {
    Label,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import axios from "axios";

import { Tag, TagGroup } from "@fluentui/react-tags";
import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import './ProductOrderHistory.css';
import Layout from "./components/Layout";
import { Base_Url, auth } from "./services/firebase";
import { CalanderSVG, CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, FilterSVG, NodataInfoSVG, RadioSelected, RadioUnSelected } from "./static/svgs";
import { format } from 'date-fns';

import { generateExelData } from "./components/ReportCloverOrderUtil";
const { startOfMonth, endOfMonth, subMonths } = require('date-fns');

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },
    tableLayout: {
        overflowX: 'auto',    // Enable horizontal scroll
        display: 'block',
    },
});

const columns = [
    { columnKey: "id", label: "Id", width: '100px' },
    { columnKey: "note", label: "Note", width: '90px' },
    { columnKey: "email", label: "Email", width: '120px' },
    { columnKey: "isguest", label: "IsGuest", width: '50px' },
    { columnKey: "paymentState", label: "Status", width: '50px' },
    { columnKey: "amount", label: "Amount", width: '100px' },
    { columnKey: "taxAmount", label: "Tax Amount", width: '80px' },
    { columnKey: "lineItems", label: "LineItems", width: '180px' },
    { columnKey: "createdTime", label: "Created Time", width: '120px' },
];


function ReportByCloverOrder() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [cloverOrderHistory, setCloverOrderHistory] = useState([]);
    const [filterCloverOrderHistory, setFilterCloverOrderHistory] = useState([]);

    const [allUsers, setAllUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [visibleTags, setVisibleTags] = useState([]);
    const [dateTags, setDateTags] = useState([]);
    const firstTagRef = useRef(null);
    const dateTagRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const [filterOptions, setFilterOptions] = useState([
        { id: 1, name: 'Paid', selected: false },
        { id: 2, name: 'Open', selected: false },
        { id: 3, name: 'Past Due', selected: false },
    ]);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        //setIsLoading(true)
        // Set the start date to one month ago
        const startDate = moment().subtract(1, 'months'); // One month ago
        const endDate = moment(); // Today

        // Set the selected dates to state
        //setSelectedStartDate(startDate);
        //setSelectedEndDate(endDate);
        //fetchDataFromAPI(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
    }, [user, loading]);

    useEffect(() => {
        if (cloverOrderHistory && cloverOrderHistory.length > 0) {
            if (visibleTags && visibleTags.length > 0) {
                // Extract the values from visibleTags for easy comparison
                //const tagValues = visibleTags.map(tag => tag.value.toLowerCase());
                // Filter the cloverOrderHistory based on tagValues matching the invoice status
                // const filtered = cloverOrderHistory.filter(invoice =>
                //     tagValues.includes(invoice.status.toLowerCase())
                // );
                //setFilterCloverOrderHistory(filtered); // Store the filtered list in state
            } else {
                setFilterCloverOrderHistory(cloverOrderHistory); // No filtering if no visible tags
            }
        }
    }, [visibleTags]);

    useEffect(() => {
        // Fetch events when the page number changes
        if (page > 1) {
        }
    }, [page]);


    const fetchDataFromAPI = async (startDate, endDate) => {
        setIsLoading(true);
        const startDatetimestamp = new Date(startDate).getTime();
        const endDatetimestamp = new Date(endDate).getTime();
        try {
            const allPaymentIntents = [];
            const config = {
                method: 'get',
                url: `${Base_Url}/getCloverOrdersByDateRangeFIR`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'Accept': "application/json"
                },
                params: {
                    fromDate: startDatetimestamp,
                    toDate: endDatetimestamp,
                    limit: 500
                }
            };
            const response = await axios.request(config);
            if (response.status === 200 && response.data) {
                const { data } = response.data;
                if (data?.length) {
                    allPaymentIntents.push(...data);
                    // allPaymentIntents.sort((a, b) => a.createdTime - b.createdTime);
                    allPaymentIntents.sort((a, b) => b.createdTime - a.createdTime);
                }
            }
            // Save all data
            setCloverOrderHistory(allPaymentIntents);
            // Filter based on visible tags if necessary
            if (visibleTags?.length) {
                const tagValues = visibleTags.map(tag => tag.value.toLowerCase());
                const filtered = allPaymentIntents.filter(invoice =>
                    tagValues.includes(invoice.status.toLowerCase())
                );
                setFilterCloverOrderHistory(filtered);
            } else {
                setFilterCloverOrderHistory(allPaymentIntents);
            }
        } catch (err) {
            console.error(err);
            //alert("An error occurred while fetching payment intents.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        // Update filter options and set selected state to false
        const updatedFilterOptions = filterOptions.map(option =>
            option.name == value ? { ...option, selected: false } : option
        );
        setFilterOptions([...updatedFilterOptions]);
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    const toggleSelect = (id) => {
        setFilterOptions((prevOptions) =>
            prevOptions.map((option) =>
                option.id === id ? { ...option, selected: !option.selected } : option
            )
        );
    };

    // Function to handle checkbox toggle
    const handleApplyFilter = (updatedFilterOptions = filterOptions) => {
        // Ensure that updatedFilterOptions is an array
        if (!Array.isArray(updatedFilterOptions)) {
            console.error('Expected updatedFilterOptions to be an array', updatedFilterOptions);
            return; // Exit the function if not an array
        }

        const selectedOptions = updatedFilterOptions.filter(option => option.selected);
        const selectedValues = selectedOptions.map(option => option.name);

        //TODO: Filter data
        const updatedTags = selectedOptions.map(option => ({
            value: option.name,
            children: option.name
        }));
        setVisibleTags(updatedTags);
        setPopupVisible(false);
    };
    const handleCancelFilter = () => {
        // Close the popup
        setPopupVisible(false);
    };

    const handleCancelDateFilter = () => {
        // Close the popup
        setSelectedOption(null)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        let startDate = null;
        let endDate = null;
        if (selectedOption == "CustomDate") {
            setCloverOrderHistory([])
            setFilterCloverOrderHistory([])
            if (selectedStartDate && selectedEndDate) {
                setSelectedOption("CustomDate");
                const formattedStartDate = selectedStartDate.format('YYYY-MM-DD'); // Use Moment's format
                const formattedEndDate = selectedEndDate.format('YYYY-MM-DD'); // Use Moment's format
                fetchDataFromAPI(formattedStartDate, formattedEndDate);
                let tagStr = `${formattedStartDate} - ${formattedEndDate}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        } else {
            setCloverOrderHistory([])
            setFilterCloverOrderHistory([])
            const today = new Date();

            switch (selectedOption) {
                case "Today":
                    startDate = moment(today).startOf('day').format('YYYY-MM-DD HH:mm'); // Start of today at 12:00 AM
                    endDate = moment(today).endOf('day').format('YYYY-MM-DD HH:mm'); // End of today at 11:59 PM
                    break;

                case "Yesterday":
                    const yesterday = moment(today).subtract(1, 'day');
                    startDate = yesterday.startOf('day').format('YYYY-MM-DD HH:mm'); // Start of yesterday at 12:00 AM
                    endDate = yesterday.endOf('day').format('YYYY-MM-DD HH:mm'); // End of yesterday at 11:59 PM
                    break;

                case "This month":
                    startDate = moment(today).startOf('month').format('YYYY-MM-DD'); // Start of current month
                    endDate = moment(today).endOf('month').format('YYYY-MM-DD'); // End of current month
                    break;

                case "Past month":
                    const pastMonth = moment(today).subtract(1, 'month');
                    startDate = pastMonth.startOf('month').format('YYYY-MM-DD'); // Start of past month
                    endDate = pastMonth.endOf('month').format('YYYY-MM-DD'); // End of past month
                    break;

                default:
                    startDate = null;
                    endDate = null;
                    break;
            }
            if (startDate && endDate) {
                fetchDataFromAPI(startDate, endDate);
                let startDateOnly = startDate.split(' ')[0];
                let endDateOnly = endDate.split(' ')[0];
                let tagStr = `${startDateOnly} - ${endDateOnly}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        }
        setPopupVisible(false);
        setPopupVisibleDate(false);
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        //        setSelectedStartDate(date);
        setSelectedStartDate(moment(date)); // Ensure it's a Moment object
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        //setSelectedEndDate(date);
        setSelectedEndDate(moment(date)); // Ensure it's a Moment object
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };

    function onGenerateExportClick(from) {
        const dataArr = filterCloverOrderHistory;
        if (dataArr.length > 0) {
            if (from == 2) {
            } else {
                generateExelData(dataArr, exportSelectedOption, allUsers)
            }
        }
    }

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <div className={"d-flex"}>
                        <div style={{ marginTop: '8px' }}>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Order Date</span>
                            <DownArowSVG />
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '20px', flexDirection: 'row', display: 'flex' }}>
                    {dateTags.length !== 0 && (<div style={{ marginTop: '5px', marginRight: '10px', color: '#202020', fontSize: 14, fontWeight: '600' }}>Applied filters:</div>
                    )}
                    {dateTags.length !== 0 && (
                        <TagGroup role="list" aria-label="Dismiss example">
                            {dateTags.map((tag, index) => (
                                <Tag
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? dateTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className="table-layout3">
                    <Table aria-label="Table with cell actions"> {/* Set min-width here */}
                        <TableHeader className="list-row2">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey} style={{ width: column.width }}> {/* Set width here */}
                                        <span className="listHeaderText">{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {filterCloverOrderHistory.length > 0 && (
                            <TableBody>
                                {filterCloverOrderHistory.map((invoice) => (
                                    <TableRow key={invoice.id}>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.id}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.note}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice?.user?.email || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label>{invoice.user.guest ? 'YES' : 'NO'}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice?.paymentState}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>${convertStripeAmount(invoice.total).toFixed(2)}</TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {/* Pass the correct lineItems data */}
                                                <TaxAmountList payments={invoice?.payments?.elements} />
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {/* Pass the correct lineItems data */}
                                                <LineItemList lineItems={invoice?.lineItems?.elements} />
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{format(new Date(invoice.createdTime), 'dd-MMM-yyyy h:mm a')}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {
                            isLoading || filterCloverOrderHistory.length > 0 ? <div /> :
                                // <TableBody>
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Report Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please date range, then click 'GENERATE REPORT' to view, export, and print the data.
                                    </div>
                                </div>
                            // </TableBody>
                        }
                    </Table>
                </div>

                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                </div>
                {popupVisible && (
                    <div className="popupInvoiceStatus" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x - 20 }}>
                        <div className="popupInvoiceStatusList">
                            {filterOptions.map((option) => (
                                <div key={option.id}>
                                    <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                        <div
                                            onClick={() => toggleSelect(option.id)}
                                            style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                        >
                                            {option.selected ? (
                                                <CheckBoxChecked />  // Your checked SVG
                                            ) : (
                                                <CheckBoxUnChecked />  // Your unchecked SVG
                                            )}
                                            <div style={{ marginLeft: '8px' }}>{option.name}</div>
                                        </div>
                                    </Label>
                                </div>
                            ))}
                        </div>
                        <div className="filterseperater"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelFilter}>Cancel</button>
                            <button className="fillButton" onClick={() => { handleApplyFilter() }}>APPLY</button>
                        </div>
                    </div>
                )}

                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}
                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y + 10, left: popupPosition.x - 50 }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '47%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    //selected={selectedStartDate}
                                    selected={selectedStartDate ? selectedStartDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div style={{ marginLeft: '3px', marginRight: '3px', marginTop: '16px', width: '18px', height: '1px', backgroundColor: '#C1C1C1' }}></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '47%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    //selected={selectedEndDate}
                                    selected={selectedEndDate ? selectedEndDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelDateFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default ReportByCloverOrder;

const LineItemList = ({ lineItems }) => {
    if (lineItems && lineItems.length > 0) {
        // Calculate the total price of all line items
        const totalAmount = lineItems.reduce((total, item) => total + item.price, 0);
        return (
            <div>
                {lineItems.map((item, index) => (

                    <div key={index}>
                        <div style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{item.name}</div>
                                <div style={styles.productName2}>${(item.price / 100).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* Display the Total Price */}
                <div style={styles.productTotal}>
                    Product Total: ${(totalAmount / 100).toFixed(2)}
                </div>
            </div>
        );
    } else {
        return <div>No line items available</div>;
    }
};
const TaxAmountList = ({ payments }) => {
    if (payments && payments.length > 0) {
        return (
            <div>
                {payments.map((item, index) => (
                    <div key={index}>
                        <div style={styles.productDetailsContainer}>
                            <div style={styles.productName2}>${(item.taxAmount / 100).toFixed(2)}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return <div>0</div>;
    }
};

function convertStripeAmount(paymentAmount) {
    // Check if paymentAmount is null, undefined, or an empty string
    if (paymentAmount === null || paymentAmount === undefined || paymentAmount === '') {
        return 0; // You can choose to return null or throw an error instead
    }
    // Ensure the paymentAmount is a number
    const amount = Number(paymentAmount);
    if (isNaN(amount)) {
        return 0; // Return 0 or handle as needed
    }
    // Divide the payment amount by 100 to convert cents to dollars
    return amount / 100;
}

const styles = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};
