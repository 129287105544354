import 'jspdf-autotable';
import { React } from 'react';
import '../ProductOrderHistory.css';
export const LineItemList = ({ lineItems }) => {
  if (lineItems && lineItems.length > 0) {
      // Calculate the total price of all line items
      const totalAmount = lineItems.reduce((total, item) => total + item.price, 0);
      return (
          <div>
              {lineItems.map((item, index) => (

                  <div key={index}>
                      <div style={styles.productDetailsContainer}>
                          <div className="d-flex" style={{}}>
                              <div style={styles.productName}>{item.name} ${(item.price / 100).toFixed(2)}</div>
                          </div>
                      </div>
                  </div>
              ))}
              {/* Display the Total Price */}
              <div style={styles.productTotal}>
                  Product Total: ${(totalAmount / 100).toFixed(2)}
              </div>
          </div>
      );
  } else {
      return <div>No line items available</div>;
  }
};

function convertStripeAmount(paymentAmount) {
  // Check if paymentAmount is null, undefined, or an empty string
  if (paymentAmount === null || paymentAmount === undefined || paymentAmount === '') {
      return 0; // You can choose to return null or throw an error instead
  }
  // Ensure the paymentAmount is a number
  const amount = Number(paymentAmount);
  if (isNaN(amount)) {
      return 0; // Return 0 or handle as needed
  }
  // Divide the payment amount by 100 to convert cents to dollars
  return amount / 100;
}

const styles = {
  productDetailsContainer: {
      display: 'flex',
      alignItems: 'center',
  },
  imageContainer: {
      marginRight: 10,
  },
  productImage: {
      width: 62,
      height: 62,
      borderRadius: 5,
  },
  productName: {
      color: '#202020',
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
  },
  productName2: {
      marginLeft: '6px',
      color: '#202020',
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
  },
  productTotal: {
      marginTop: '2px',
      color: '#202020',
      fontFamily: 'Nunito Sans',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px',
  },
  productPrice: {
      fontSize: 12,
      color: '#535353'
  },
  separator: {
      width: '100%',
      borderTop: '1px solid #ccc',
      marginTop: 10,
      marginBottom: 10
  }
};

