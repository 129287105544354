import { Spinner } from "@fluentui/react";
import {
  Button,
  Divider,
  Input,
  Label,
  makeStyles,
  shorthands
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import {
  Dismiss24Regular
} from "@fluentui/react-icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { Timestamp } from "firebase/firestore";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Controller, useForm } from "react-hook-form";
import { Base_Url, auth, trackException } from "../../services/firebase";
import UserDataService from "../../services/userDataService";
import { BankCardSVG, BankDeleteSVG, BankSVG } from "../../static/svgs";
import DeleteEventDialog from "../DeleteEventDialog";

export const InitialWalletState = {
  memberships: [],
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
  },
  control: {
    maxWidth: "300px",
  },
});

function UserWalletForm(props) {
  const styles = useStyles();
  const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields }, } = useForm({
    defaultValues: props.selectedUser ? props.selectedUser : InitialWalletState,
  });
  const stripe = useStripe();
  const elements = useElements();

  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [cardList, setCardList] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [seletedCardObj, setSeletedCardObj] = useState(null);
  const [seletedBankObj, setSeletedBankObj] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
  const [isDeleteBankOpen, setIsDeleteBankOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [isBalanceLoader, setBalanceLoader] = React.useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [bankAccount, setBankAccount] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [newbalance, setNewbalance] = useState(0);
  const [balanceDesc, setBalanceDesc] = useState('');
  const [drivingFleetBal, setDrivingFleetBal] = useState(0);

  const [selectedMembershipPlaceholder, setSeletedMembershipPlaceholder] = useState("");
  const [accountHolderData, setAccountHolderData] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [memberHasParent, setMemberHasParent] = useState(false);

  const onSubmit = async (formdata) => {
    var data = props.selectedUser;
    data.memberships = formdata.memberships;
    var id = "";
    if (props.selectedUser) {
      id = props.selectedUser.id;
      await UserDataService.updateUser(props.selectedUser.id, data);
    } else {
    }
    props.setIsOpen(false);
    props.updateGrid();
  };

  async function updateCustomerIdInFir(customerId) {
    if (memberHasParent) { return }
    await UserDataService.updateUser(props.selectedUser.id, {
      stripeCustomerId: customerId,
    });
  }

  async function onAddBalanceClick() {
    if (newbalance && parseInt(newbalance) > 0) {
      let mainUserId = getParentAccountId()
      setBalanceLoader(true)
      try {
        const userDoc = await UserDataService.getUser(mainUserId);
        let userDocObj = userDoc.data();
        if (userDocObj) {
          let totalAmount = 0
          if (userDocObj.totalAmount && parseInt(userDocObj.totalAmount) > 0) {
            totalAmount = parseFloat(userDocObj.totalAmount) + parseFloat(newbalance)
          } else {
            totalAmount = parseFloat(newbalance)
          }
          await UserDataService.updateUser(mainUserId, {
            totalAmount: totalAmount,
          });
          let data = {
            "type": "Admin_Gift",
            "refId": "",
            "creditType": "Credit",
            "amount": newbalance,
            "userId": mainUserId,
            "currentUserId": props.selectedUser.id,
            "createdBy": user.uid,
            "isDrivingFeet": false,
            "createdAt": Timestamp.now(),
            "desription": balanceDesc || ''
          };
          const addeddata = await UserDataService.addTransaction(data);
          setNewbalance(0)
          alert("Balance Add successfully.")
          setBalanceLoader(false)
          props.setIsOpen(false);
          props.updateGrid();
        }
      } catch (error) {
        setBalanceLoader(false)
        console.log("error->", error)
      }
    }
  }

  async function onSubtractBalanceClick() {
    if (newbalance && parseInt(newbalance) > 0) {
      setBalanceLoader(true)
      let mainUserId = getParentAccountId()
      try {
        const userDoc = await UserDataService.getUser(mainUserId);
        let userDocObj = userDoc.data();
        if (userDocObj) {
          let totalAmount = 0
          if (userDocObj.totalAmount && parseInt(userDocObj.totalAmount) > 0) {
            totalAmount = parseFloat(userDocObj.totalAmount) - parseFloat(newbalance)
            if (totalAmount >= 0) {
              await UserDataService.updateUser(mainUserId, {
                totalAmount: totalAmount,
              });
              let data = {
                "type": "Subtract_By_Admin",
                "refId": "",
                "creditType": "Debit",
                "amount": newbalance,
                "userId": mainUserId,
                "currentUserId": props.selectedUser.id,
                "createdBy": user.uid,
                "isDrivingFeet": false,
                "createdAt": Timestamp.now(),
                "desription": balanceDesc || ''
              };
              const addeddata = await UserDataService.addTransaction(data);
              setNewbalance(0)
              alert("Balance Subtract successfully.")
              setBalanceLoader(false)
              props.setIsOpen(false);
              props.updateGrid();
            } else {
              setBalanceLoader(false)
              alert("Insufficient balance in user wallet.");
            }
          } else {
            setBalanceLoader(false)
            alert("Insufficient balance in user wallet.");
          }
        }
      } catch (error) {
        setBalanceLoader(false)
        alert(error);
        console.log("error->", error)
      }
    }
  }

  async function onAddDrivingFleetBalanceClick() {
    if (drivingFleetBal && parseInt(drivingFleetBal) > 0) {
      setBalanceLoader(true)
      try {
        let mainUserId = getParentAccountId()
        const userDoc = await UserDataService.getUser(mainUserId);
        let userDocObj = userDoc.data();
        if (userDocObj) {
          let drivingFleetBalPoint = getPointFromUSD(drivingFleetBal)
          let aTotalPoint = 0
          if (userDocObj.totalPoint && parseInt(userDocObj.totalPoint) > 0) {
            aTotalPoint = parseFloat(userDocObj.totalPoint) + drivingFleetBalPoint
          } else {
            aTotalPoint = drivingFleetBalPoint
          }
          await UserDataService.updateUser(mainUserId, {
            totalPoint: aTotalPoint,
          });
          let data = {
            "type": "Admin_DrivingFleet_Gift",
            "refId": "",
            "creditType": "Credit",
            "points": drivingFleetBalPoint,
            "userId": mainUserId,
            "currentUserId": props.selectedUser.id,
            "createdBy": user.uid,
            "isDrivingFeet": true,
            "createdAt": Timestamp.now(),
          };
          const addeddata = await UserDataService.addTransaction(data);
          setNewbalance(0)
          alert("Balance Add successfully.")
          setBalanceLoader(false)
          props.setIsOpen(false);
          props.updateGrid();
        }
      } catch (error) {
        setBalanceLoader(false)
        console.log("error->", error)
      }
    }
  }

  async function onSubtractDrivingFleetBalanceClick() {
    if (drivingFleetBal && parseInt(drivingFleetBal) > 0) {
      setBalanceLoader(true)
      try {
        let mainUserId = getParentAccountId()
        const userDoc = await UserDataService.getUser(mainUserId);
        let userDocObj = userDoc.data();
        if (userDocObj) {
          let drivingFleetBalPoint = getPointFromUSD(drivingFleetBal)
          let aTotalPoint = 0
          if (userDocObj.totalPoint && parseInt(userDocObj.totalPoint) > 0) {
            aTotalPoint = parseFloat(userDocObj.totalPoint) - drivingFleetBalPoint
          }
          await UserDataService.updateUser(mainUserId, {
            totalPoint: aTotalPoint,
          });
          let data = {
            "type": "Admin_Debit_DrivingFleet",
            "refId": "",
            "creditType": "Debit",
            "points": drivingFleetBalPoint,
            "amount": drivingFleetBal,
            "userId": mainUserId,
            "currentUserId": props.selectedUser.id,
            "createdBy": user.uid,
            "isDrivingFeet": true,
            "createdAt": Timestamp.now(),
          };
          const addeddata = await UserDataService.addTransaction(data);
          setNewbalance(0)
          alert("Balance Subtract successfully.")
          setBalanceLoader(false)
          props.setIsOpen(false);
          props.updateGrid();
        }
      } catch (error) {
        setBalanceLoader(false)
        console.log("error->", error)
      }
    }
  }

  async function getCardToken() {
    if (memberHasParent) {
      return;
    }
    if (stripeCustomerId) {
      try {
        const cardElement = elements.getElement("card");
        const res = await stripe.createToken(cardElement);
        if (res && res.token.card) {
          cardElement.clear();
          addCartOnFile(res.token.id);
        }
      } catch (error) {
        alert(error);
        trackException(JSON.stringify(error))
      }
    }
  }

  const getStripeSubscriptionList = async () => {
    let config = {
      method: "get",
      url: `${Base_Url}/getStripeAllSubsriptionList`,
      headers: {
        "Content-Type": "application/json",
        "Acess-Control-Allow-Origin": "*",
        Authorization: `${user.accessToken}`,
        Accept: "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setSubscriptionList(response?.data?.data?.filter((data) => data?.metadata?.SubCategory !== "Addon"));
          //setSubscriptionAddOnsList(response?.data?.data?.filter((data) => data?.metadata?.SubCategory === "Addon"));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function generateBankToken() {
    if (memberHasParent) {
      return
    }
    if (
      accountName &&
      accountName.trim() != "" &&
      routingNumber &&
      routingNumber.trim() != "" &&
      accountNumber &&
      accountNumber != ""
    ) {
      let data = qs.stringify({
        "bank_account[country]": "US",
        "bank_account[currency]": "usd",
        "bank_account[account_holder_name]": accountName,
        "bank_account[account_holder_type]": "individual",
        "bank_account[routing_number]": "110000000",
        "bank_account[account_number]": "000123456789",
      });
      let config = {
        method: "post",
        url: "https://api.stripe.com/v1/tokens",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SK_KEY}`,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            addBankOnFile(response.data.id);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async function getStripCustomerId() {
    if (props.selectedUser && props.selectedUser.email) {
      setIsLoading(true);
      let config = {
        method: "get",
        url: `${Base_Url}/search-stripeuser?emailId=${props.selectedUser.email}`,
      };
      axios
        .request(config)
        .then((response) => {
          if (response && response.data && response.data.data) {
            let customer = response.data.data[0];
            if (customer && customer.id) {
              setStripeCustomerId(customer.id);
              getCardListByCustomerId(customer.id);
              getPaymentMethodByCustomerId(customer.id);
              updateCustomerIdInFir(customer.id);
            } else {
              //Create Customer Id in stripe
              console.log("No Customer...")
              createStripeCustomer()
            }
          } else {
            setIsLoading(false);
            setStripeCustomerId(null);

          }
        })
        .catch((error) => {
          setIsLoading(false);
          setStripeCustomerId(null);
        });
    }
  }
  async function createStripeCustomer() {
    let data = JSON.stringify({
      "name": `${props.selectedUser.firstName}`,
      "description": "Warehouse",
      "email": `${props.selectedUser.email}`
    });
    let config = {
      method: 'post',
      url: `${Base_Url}/createStripeCustomer`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        if (response && response.data != "") {
          if (response.data && response.data.data && response.data.data.id) {
            let customer = response.data.data
            if (customer && customer.id) {
              setStripeCustomerId(customer.id);
              getCardListByCustomerId(customer.id);
              getPaymentMethodByCustomerId(customer.id);
              updateCustomerIdInFir(customer.id);
            }
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error)
        trackException(JSON.stringify(error))
      });
  }

  async function getCardListByCustomerId(stripeCustomerId) {
    if (props.selectedUser) {
      let config = {
        method: "get",
        url: `${Base_Url}/list-cards?userId=${stripeCustomerId}`,
      };
      axios
        .request(config)
        .then((response) => {
          if (response && response.data) {
            setCardList(response.data);
          } else {
            setCardList([]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setCardList([]);
          setIsLoading(false);
          trackException(JSON.stringify(error))
        });
    }
  }

  async function getBankByCustomerId(stripeCustomerId) {
    //TODO: Set dynamic email address
    if (props.selectedUser) {
      let config = {
        method: "get",
        url: `${Base_Url}/list-bankaccounts?customerId=${stripeCustomerId}`,
      };
      axios
        .request(config)
        .then((response) => {
          if (response && response.data) {
            setBankAccount(response.data.data);
          } else {
            setBankAccount(null);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setBankAccount(null);
          setIsLoading(false);
        });
    }
  }

  async function getPaymentMethodByCustomerId(stripeCustomerId) {
    //TODO: Set dynamic email address
    if (props.selectedUser) {
      let config = {
        method: "get",
        url: `${Base_Url}/getListPaymentMethods?customerId=${stripeCustomerId}`,
      };
      axios
        .request(config)
        .then((response) => {
          if (response && response.data) {
            const resArr = response.data.data;
            let bankList = resArr
              .filter((data) => data.type === "bank_account")
              .map((data) => ({
                id: data.id,
                last4: data.bank_account.last4,
                bank_name: data.bank_account.bank_name,
              }));
            let bankList2 = resArr
              .filter((data) => data.type === "us_bank_account")
              .map((data) => ({
                id: data.id,
                last4: data.us_bank_account.last4,
                bank_name: data.us_bank_account.bank_name,
              }));
            setBankAccount([...bankList, ...bankList2]);
          } else {
            setBankAccount(null);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setBankAccount(null);
          setIsLoading(false);
        });
    }
  }

  async function addCartOnFile(cardToken) {
    let data = JSON.stringify({
      customerId: stripeCustomerId,
      token: cardToken,
    });
    let config = {
      method: "post",
      url: `${Base_Url}/add-card`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response && response.data) {
          getCardListByCustomerId(stripeCustomerId);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function addBankOnFile(bankToken) {
    if (memberHasParent) {
      return
    }
    let data = JSON.stringify({
      customerId: stripeCustomerId,
      bankToken: bankToken,
    });
    let config = {
      method: "post",
      url: `${Base_Url}/add-bankaccount`,
      //url: `https://1df4-2405-201-1012-c14b-1448-8f43-b8bb-85bb.ngrok-free.app`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response && response.data) {
          getBankByCustomerId(stripeCustomerId);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function deleteCard(cardId) {
    if (memberHasParent) {
      return;
    }
    let config = {
      method: "get",
      url: `${Base_Url}/delete-card?userId=${stripeCustomerId}&cardId=${cardId}`,
    };
    axios
      .request(config)
      .then((response) => {
        if (response && response.data) {
          getCardListByCustomerId(stripeCustomerId);
        }
      })
      .catch((error) => {
        alert(error);
      });
    setIsDeleteOpen(false);
  }

  async function deleteBankAccount(accountId) {
    let config = {
      method: "get",
      url: `${Base_Url}/delete-bankaccount?customerId=${stripeCustomerId}&bankAccountId=${accountId}`,
    };
    axios
      .request(config)
      .then((response) => {
        if (response && response.data) {
          getBankByCustomerId(stripeCustomerId);
        }
      })
      .catch((error) => {
        alert(error);
      });
    setIsDeleteBankOpen(false);
  }

  async function checkUserHasParent(userdata) {
    if (userdata && userdata.accountHolderId && userdata.accountHolderId != "" && userdata.accountHolderId != userdata.id) {
      try {
        const userDoc = await UserDataService.getUser(userdata.accountHolderId);
        let parentuser = userDoc.data();
        if (parentuser) {
          setMemberHasParent(true)
          setAccountHolderData(parentuser)
          setStripeData(parentuser)
        }
      } catch (error) {
        setMemberHasParent(false)
        setStripeData(userdata)
      }
    } else {
      setMemberHasParent(false)
      setStripeData(userdata)
    }
  }

  async function setStripeData(userDocObj) {
    //TODO: check customer id exist in Firestore
    if (userDocObj && userDocObj.stripeCustomerId && userDocObj.stripeCustomerId != "") {
      setStripeCustomerId(userDocObj.stripeCustomerId);
      getCardListByCustomerId(userDocObj.stripeCustomerId);
      getPaymentMethodByCustomerId(userDocObj.stripeCustomerId);
    } else {
      getStripCustomerId();
    }
  }

  async function getUserDetails() {
    if (props.selectedUser && props.selectedUser.id) {
      setIsLoading(true);
      try {
        const userDoc = await UserDataService.getUser(props.selectedUser.id);
        let userDocObj = userDoc.data();
        if (userDocObj) {
          setCurrentUserData(userDocObj)
          checkUserHasParent(userDocObj)
        }
      } catch (error) {
        trackException(JSON.stringify(error))
        setIsLoading(false);
      }
    }
  }

  const removeCard = (cardObj) => {
    setSeletedCardObj(cardObj);
    setIsDeleteOpen(true);
  };

  const DeleteUser = async () => {
    deleteCard(seletedCardObj.id);
  };

  const DeleteBankAC = async () => {
    deleteBankAccount(seletedBankObj.id);
  };

  const getUserData = () => {
    if (memberHasParent && accountHolderData) {
      return accountHolderData;
    } else {
      return currentUserData;
    }
  }
  const getMemberBalance = () => {
    let userInfo = getUserData()
    return userInfo && userInfo.totalAmount && parseInt(userInfo.totalAmount) > 0 ? parseFloat(userInfo.totalAmount).toFixed(2) : '0.00'
  }

  const getMemberDrivingFeetBalance = () => {
    let userInfo = getUserData()
    return userInfo && userInfo.totalPoint && parseInt(userInfo.totalPoint) > 0 ? parseInt(userInfo.totalPoint) : 0
  }

  const getParentAccountId = () => {
    let userInfo = getUserData()
    return userInfo.id
  }

  useEffect(() => {
    const formfields = ["memberships", "id"];
    if (props.selectedUser) {
      setSeletedCardObj(null);
      setCardList([]);
      setBankAccount(null);
      setSubscriptionList([]);
      getStripeSubscriptionList();
      formfields.forEach((field) =>
        setValue(
          field,
          props.selectedUser[field]
            ? props.selectedUser[field]
            : InitialWalletState[field]
        )
      );
      getUserDetails();
      setDrivingFleetBal(0);
      if (
        props.selectedUser.memberships &&
        props.selectedUser.memberships.length > 0
      ) {
        let filteredSubscrption = subscriptionList
          .filter(data => props.selectedUser.memberships.includes(data.id))
          .map(data => data.description);
        setSeletedMembershipPlaceholder(filteredSubscrption.join(', '));
      } else {
        setSeletedMembershipPlaceholder("");
      }
    } else {
      setSeletedMembershipPlaceholder("");
    }
  }, [props.selectedUser]);

  return (
    <DrawerOverlay
      size={"medium"}
      position={"right"}
      open={props.isOpen}
      style={{ width: "504px" }}
      onOpenChange={(_, { open }) => props.setIsOpen(open)}
    >
      <DrawerHeader style={{
        background: "#F7F7F7",
        padding: "26px 32px"
      }}>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => props.setIsOpen(false)}
            />
          }
        >
          {props.selectedUser ? "Payment & Memberships" : "New"}
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.root}
          onReset={reset}
          id={props.selectedUser ? props.selectedUser.id : "New"}
        >
          <Label className={"side-drawer-field__label"} style={{ marginTop: 32 }}>
            <div className={"d-flex justify-space-between"}>
              <div>Stripe Customer Id:</div>
              <div style={{
                color: '#000',
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '28px' // You can specify this directly as a string
              }}>{stripeCustomerId}</div>
            </div>
            {
              accountHolderData && (
                <Label>
                  Parent Account: {accountHolderData.firstName} {accountHolderData.lastName}
                </Label>
              )
            }
          </Label>
          <Divider className={"side-drawer-divider-gap"} />
          <Label className={"side-drawer-field__label"}>Card</Label>
          {cardList &&
            cardList.length > 0 &&
            cardList.map((obj, index) => {
              console.log(obj)
              return (
                <div>
                  <div
                    className={"bank-card-layout"}
                  >
                    <div><BankCardSVG /></div>
                    <div className={"card-number-color"}>**** **** **** ${obj.last4}</div>
                    <div>
                      <div
                        className={"pointer"}
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          removeCard(obj);
                        }}
                      >
                        <BankDeleteSVG />
                      </div>
                    </div>

                  </div>
                  {/*    )}*/}
                  {/*/>*/}

                </div>
              );
            })}
          {isLoading ? (
            <div />
          ) : cardList && cardList.length < 2 ? (
            <>
              <div
                className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                style={{ marginTop: 32 }}
              // style={{
              //   border: "1px solid #ccc",
              //   padding: "10px",
              //   borderRadius: "5px",
              //   marginBottom: "10px",
              // }}
              >
                <CardElement />
              </div>
              <button
                className={"card-add-button pointer"}
                onClick={() => {
                  getCardToken();
                }}
              >
                Add Card
              </button>
            </>
          ) : (
            <div />
          )}
          <Divider appearance="strong" className={"side-drawer-divider-gap"}></Divider>

          <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
            <div><BankSVG /></div>
            <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>Bank</Label>
          </div>
          {isLoading ? (
            <div />
          ) : (
            bankAccount &&
            bankAccount.length > 0 &&
            bankAccount.map((obj, index) => {
              return (
                <div
                  key={index}
                  style={{

                    width: "100%",
                  }}
                >
                  {/*<Controller*/}
                  {/*    name={`bank${index}`}*/}
                  {/*    control={control}*/}
                  {/*    rules={{required: false}}*/}
                  {/*    render={({field}) => (*/}
                  <div
                    style={{ width: "95%", marginBottom: "24px" }}
                    className={"bank-card-layout"}


                  >
                    {`Account Number (last4): ${obj.last4}`}
                  </div>
                  {/*    )}*/}
                  {/*/>*/}
                </div>
              );
            })
          )}

          <>
            <Label className={"side-drawer-field__label"}>Account holder name</Label>
            <Controller
              name="bank.account_holder_name"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                  {...field}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              )}
            />
            <Label className={"side-drawer-field__label"}>Routing Number</Label>
            <Controller
              name="bank.routing_number"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                  {...field}
                  onChange={(e) => setRoutingNumber(e.target.value)}
                />
              )}
            />
            <Label className={"side-drawer-field__label"}>Account Number</Label>
            <Controller
              name="bank.account_number"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                  {...field}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              )}
            />
            <button
              className={"card-add-button pointer"}
              onClick={() => {
                generateBankToken();
              }}
            >
              Add Bank
            </button>
          </>

          <Divider appearance="strong" className={"side-drawer-divider-gap"}></Divider>
          <Label className={"side-drawer-field__label"}>Balance: ${getMemberBalance()}</Label>
          <Label className={"side-drawer-field__label"}>Enter Balance $</Label>
          <Controller
            name="newbalance"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                {...field}
                onChange={(e) => setNewbalance(e.target.value)}
              />
            )}
          />
          <Label className={"side-drawer-field__label"}>Description</Label>
          <Controller
            name="desription"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                {...field}
                onChange={(e) => setBalanceDesc(e.target.value)}
              />
            )}
          />
          {
            isBalanceLoader ?
              <Spinner label="Loading..." />
              :
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <button
                  type="button"
                  className={"card-add-button pointer"}
                  onClick={() => {
                    onAddBalanceClick()
                  }}
                >
                  + Add Balance
                </button>
                <button
                  type="button"
                  className={"card-add-button pointer"}
                  onClick={() => {
                    onSubtractBalanceClick()
                  }}
                >
                  - Subtract Balance
                </button>
              </div>
          }

          <Divider appearance="strong" className={"side-drawer-divider-gap"}></Divider>
          <Label className={"side-drawer-field__label"}>Driving Fleet Credit: {getUSDFromPoint(getMemberDrivingFeetBalance())} USD {`(${getMemberDrivingFeetBalance()} Points)`}</Label>
          <Label className={"side-drawer-field__label"}>Enter Driving Fleet Balance $</Label>
          <Controller
            name="newbalance"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                {...field}
                onChange={(e) => setDrivingFleetBal(e.target.value)}
              />
            )}
          />
          {
            isBalanceLoader ?
              <Spinner label="Loading..." />
              :
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <button
                  type="button"
                  className={"card-add-button pointer"}
                  onClick={() => {
                    onAddDrivingFleetBalanceClick()
                  }}
                >
                  + Add Credit
                </button>
                <button
                  type="button"
                  className={"card-add-button pointer"}
                  onClick={() => {
                    onSubtractDrivingFleetBalanceClick()
                  }}
                >
                  - Subtract Credit
                </button>
              </div>
          }

          {/* <Label className={"side-drawer-field__label"}>Memberships</Label>
          <Controller
            name="memberships"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Dropdown
                multiselect={true}

                className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                onOptionSelect={(ev, data) =>
                  field.onChange(data.selectedOptions)
                }
                selectedOptions={field.value}
                placeholder={selectedMembershipPlaceholder ? selectedMembershipPlaceholder : "Select Memberships"}
              >
                {subscriptionList && subscriptionList.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                ))}
              </Dropdown>
            )}
          /> */}
          <div className="btnUpdateActions">
            {/* <button
              appearance="primary"
              type="submit"
              className="wallet-Update-button"

            >
              {"Update"}
            </button> */}
            {/* <Button className="btnCancel" icon={<CalendarCancelFilled />}
                            onClick={() => props.setIsOpen(false)}>Cancel</Button> */}
          </div>

          <DeleteEventDialog
            isDeleteDialogOpen={isDeleteOpen}
            setIsDeleteDialogOpen={setIsDeleteOpen}
            DeleteEvent={DeleteUser}
            text={"Card"}
          />
          <DeleteEventDialog
            isDeleteDialogOpen={isDeleteBankOpen}
            setIsDeleteDialogOpen={setIsDeleteBankOpen}
            DeleteEvent={DeleteBankAC}
            text={"Bank account"}
          />
        </form>
      </DrawerBody>
    </DrawerOverlay>
  );
}

export default UserWalletForm;

const getUSDFromPoint = (point) => {
  return parseFloat(point * 20)
}
const getPointFromUSD = (balance) => {
  return parseFloat(balance / 20)
}