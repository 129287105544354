import ExportJsonExcel from 'js-export-excel';
import Papa from "papaparse";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';
import moment from 'moment';

const tempColumn = [
    'Id',
    'RefrenceId',
    'Date',
    'User',
    'Email',
    'Transaction Type',
    'Amount',
    'Type'
]
function convertStripeAmount(paymentAmount) {
    // Check if paymentAmount is null, undefined, or an empty string
    if (paymentAmount === null || paymentAmount === undefined || paymentAmount === '') {
        return 0; // You can choose to return null or throw an error instead
    }
    // Ensure the paymentAmount is a number
    const amount = Number(paymentAmount);
    if (isNaN(amount)) {
        return 0; // Return 0 or handle as needed
    }
    // Divide the payment amount by 100 to convert cents to dollars
    return amount / 100;
}
function formatDate(unixTimestamp) {
    // Check if the input is null, undefined, or not a valid number
    if (!unixTimestamp || isNaN(unixTimestamp)) {
        return "Invalid Date";
    }

    // Multiply by 1000 to convert seconds to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Check if the created date is invalid (e.g., input was not a number)
    if (isNaN(date.getTime())) {
        return "Invalid Date";
    }

    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export function generateExelData(dataArr, exportSelectedOption, allUsers) {

    const formattedData = dataArr && dataArr.map((item) => {
        // Calculate the total price for all products in the item
        const user = allUsers.filter(t => t.id == item.userId)[0];
       
        if (item) {
            return {
                'Id': item.id,
                'RefrenceId': item?.refId,
                'Date': item.date,
                'User': item.user,
                'Email': item.email,
                'Transaction Type': item?.transactionType,
                'Amount': item.amount,
                'Type': item.type,
            };
        }
        return {
            'Id': '',
            'RefrenceId': '',
            'Date': '',
            'User': '',
            'Email': '',
            'Transaction Type': '',
            'Amount': '',
            'Type': ''
        };
    });

    const option = {
        fileName: 'reports-userwallet',
        datas: [
            {
                sheetData: formattedData,  // Assume `formattedData` is your dataset
                sheetName: 'Orders',
                sheetFilter: tempColumn,
                sheetHeader: tempColumn,
                columnWidths: tempColumn.map(col => parseInt(col.width, 10) / 20)  // Adjust if needed for your export
            },
        ],
    };


    if (exportSelectedOption === 'Excel') {
        const toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    } else if (exportSelectedOption === 'CSV') {
        exportToCSV(formattedData, 'reports-userwallet');
    } else if (exportSelectedOption === 'PDF') {
        exportToPDF(dataArr,allUsers, 'reports-userwallet');
    } else if (exportSelectedOption === 'Print') {
        //printData(formattedData);
    }
}

export function exportToCSV(data, fileName) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function exportToPDF(data,allUsers, fileName) {
    const doc = new jsPDF('landscape');  // Use landscape mode for more horizontal space
    const tableColumn = tempColumn;
    const tableRows = [];

    data.forEach(item => {
        
        const row = [
            item.id,
            item?.refId,
            item.date,
            item.user,
            item.email,
            item.transactionType,
            item.amount,
            item?.type,
        ];
        tableRows.push(row);
    });

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 1.5,  // Adjust as needed
        styles: {
            cellPadding: 2,
            fontSize: 6,
            overflow: 'linebreak',
        },
        columnStyles: {
            0: { cellWidth: 32 },
            1: { cellWidth: 26 },
            2: { cellWidth: 30 },
            3: { cellWidth: 26 },
            4: { cellWidth: 26 },
            5: { cellWidth: 26 },
            6: { cellWidth: 26 },
            7: { cellWidth: 26 },
        },
        theme: 'striped',  // Optional: adds a striped effect to the rows
        headStyles: { fillColor: [22, 160, 133] },  // Optional: change header color
    });

    doc.save(`${fileName}.pdf`);
}