import {
    Button,
    Label,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Tag, TagGroup } from "@fluentui/react-tags";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import './ProductOrderHistory.css';
import CustomSearchInput2 from "./components/CustomSearchInput2";
import Layout from "./components/Layout";
import { generateExelData } from "./components/ReportUserWalletUtil";
import { Base_Url, auth, db, trackException } from "./services/firebase";
import orderHistoryDataService from "./services/orderHistoryDataService";
import userDataService from "./services/userDataService";
import { CalanderSVG, CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, FilterSVG, NodataInfoSVG, RadioSelected, RadioUnSelected } from "./static/svgs";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "id", label: "Id", width: '18%' },
    { columnKey: "refrenceId", label: "RefrenceId", width: '18%' },
    { columnKey: "date", label: "Date", width: '15%' },
    { columnKey: "username", label: "User", width: '15%' },
    { columnKey: "type", label: "Transaction Type", width: '18%' },
    { columnKey: "amount", label: "Amount", width: '14%' },
    { columnKey: "type", label: "Type", width: '10%' },
];

function UserWalletHistory() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [allTransactionHistory, setAllTransactionHistory] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrderHistory, setFilteredOrderHistory] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(30); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [members, setMembers] = useState([
        { id: 1, name: 'John', selected: false },
        { id: 2, name: 'Jane', selected: false },
        { id: 3, name: 'Doe', selected: false }
    ]);
    const [searchMembers, setSearchMembers] = useState('');
    const [visibleTags, setVisibleTags] = useState([]);
    const [seletedFilterMember, setSeletedFilterMember] = useState([]);
    const firstTagRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch events when the page number changes
        if (page > 1) {
            loadMoreEvents();
        }
    }, [page]);


    const loadMoreEvents = async () => {
        //TODO: Load More
    };


    const fetchDataFromAPI = async (startDate, endDate) => {
        setIsLoading(true);
        try {
            const config = {
                method: 'get',
                url: `${Base_Url}/getCombinedTransactionAndCloverOrders`,
                // url: `http://127.0.0.1:5001/warehouse-prod-40386/us-central1/getCombinedTransactionAndCloverOrders`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'Accept': "application/json"
                },
                params: {
                    fromDate: startDate,
                    toDate: endDate,
                    limit: 500
                }
            };
            const response = await axios.request(config);
            if (response.status === 200 && response.data) {
                const { data } = response.data;
                let allEventArr = data
                setAllTransactionHistory(allEventArr)
                if (visibleTags && visibleTags.length > 0) {
                    const selectedMembers = members.filter(member => member.selected);
                    const selectedMemberids = selectedMembers.map(member => member.id);
                    // Apply filter based on the collection type
                    const filtered = allEventArr.filter(item => {
                        if (item.collection === 'CloverOrderHistory') {
                            // For CloverOrderHistory, check the user id
                            return selectedMemberids.includes(item.user?.id);
                        } else if (item.collection === 'TransactionHistory') {
                            // For TransactionHistory, check the userId
                            return selectedMemberids.includes(item.userId);
                        }
                        return false;
                    });
                    setFilteredOrderHistory(filtered);
                } else {
                    setFilteredOrderHistory(allEventArr)
                }
            }
            setIsLoading(false);
        } catch (err) {
            console.log("err->", err)
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserList = async () => {
        try {
            if (!!user) {
                const usersQuery = await userDataService.getAllUser();
                let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setAllUsers(allUserList);
                const transformedArray = allUserList.map(obj => ({
                    id: obj.id,
                    name: `${obj.firstName} ${obj.lastName}`,
                    email: obj.email,
                    selected: false
                }));
                setMembers(transformedArray)
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserList()
    }, [user, loading]);

    useEffect(() => {
        if (visibleTags && visibleTags.length > 0) {
            const selectedMembers = members.filter(member => member.selected);
            const selectedMemberids = selectedMembers.map(member => member.id);
            // Apply filter based on the collection type
            const filtered = allTransactionHistory.filter(item => {
                if (item.collection === 'CloverOrderHistory') {
                    // For CloverOrderHistory, check the user id
                    return selectedMemberids.includes(item.user?.id);
                } else if (item.collection === 'TransactionHistory') {
                    // For TransactionHistory, check the userId
                    return selectedMemberids.includes(item.userId);
                }
                return false;
            });
            setFilteredOrderHistory(filtered);
            //Apply member filter
        } else {
            setFilteredOrderHistory(allTransactionHistory);
        }
    }, [visibleTags]);

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allTransactionHistory.filter(event =>
            event.id.toLowerCase().includes(lowerSearch)
        );
        setFilteredOrderHistory([]);
        setFilteredOrderHistory(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        // const value = event.target.value;
        // setSearchTerm(value);
        // debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    const handleSearchMember = event => {
        const value = event.target.value;
        setSearchMembers(value);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        setMembers(prevMembers =>
            prevMembers.map(member =>
                member.id === value ? { ...member, selected: false } : member
            )
        );
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };

    // Function to handle checkbox toggle
    const toggleSelect = (id) => {
        setMembers((prevMembers) =>
            prevMembers.map((member) =>
                member.id === id ? { ...member, selected: !member.selected } : member
            )
        );
    };

    const handleApplyMemberFilter = () => {
        // Update visible tags
        const selectedMembers = members.filter(member => member.selected);
        const updatedTags = selectedMembers.map(member => ({
            value: member.id,
            children: member.name
        }));
        setVisibleTags(updatedTags);
        // Close the popup
        setPopupVisible(false);
    };

    const handleCancelMemberFilter = () => {
        // Close the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancel = () => {
        // Close the popup
        setSelectedOption(null)
        setFilteredOrderHistory(allTransactionHistory)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        let startDate = null;
        let endDate = null;
        if (selectedOption == "CustomDate") {
            if (selectedStartDate && selectedEndDate) {
                setSelectedOption("CustomDate");
                const formattedStartDate = selectedStartDate.toISOString().split('T')[0]; // Use Moment's format
                const formattedEndDate = selectedEndDate.toISOString().split('T')[0]; // Use Moment's format
                fetchDataFromAPI(formattedStartDate, formattedEndDate);
            }
        } else {
            const today = new Date();
            switch (selectedOption) {
                case "Today":
                    startDate = moment(today).startOf('day').format('YYYY-MM-DD HH:mm'); // Start of today at 12:00 AM
                    endDate = moment(today).endOf('day').format('YYYY-MM-DD HH:mm'); // End of today at 11:59 PM
                    break;
                case "Yesterday":
                    const yesterday = moment(today).subtract(1, 'day');
                    startDate = yesterday.startOf('day').format('YYYY-MM-DD HH:mm'); // Start of yesterday at 12:00 AM
                    endDate = yesterday.endOf('day').format('YYYY-MM-DD HH:mm'); // End of yesterday at 11:59 PM
                    break;
                case "This month":
                    startDate = moment(today).startOf('month').format('YYYY-MM-DD'); // Start of current month
                    endDate = moment(today).endOf('month').format('YYYY-MM-DD'); // End of current month
                    break;
                case "Past month":
                    const pastMonth = moment(today).subtract(1, 'month');
                    startDate = pastMonth.startOf('month').format('YYYY-MM-DD'); // Start of past month
                    endDate = pastMonth.endOf('month').format('YYYY-MM-DD'); // End of past month
                    break;
                default:
                    startDate = null;
                    endDate = null;
                    break;
            }
            if (startDate && endDate) {
                fetchDataFromAPI(startDate, endDate);
            }
        }
        if (selectedOption) {
            setPopupVisible(false);
            setPopupVisibleDate(false);
        } else {
            setPopupVisible(false);
            setPopupVisibleDate(false);
        }
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        setSelectedStartDate(date);
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        setSelectedEndDate(date);
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };

    function onGenerateExportClick() {
        const dataArr = filteredOrderHistory;
        const processedTransactions = dataArr.map(item => processTransaction(item, allUsers));
        generateExelData(processedTransactions, exportSelectedOption, allUsers)
        setPopupVisibleExport(false)
    }

    const CloverWalletRow = (item) => {
        const intentCreatedAt = moment(item.createdTime).format('MM/DD/YYYY, HH:mm');

        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        <div style={{ lineBreak: 'anywhere' }}>{item.id}</div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{ lineBreak: 'anywhere' }}>{item.refId}</div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <Label>{item.user && item.user.firstName ? item.user.firstName : ''} &nbsp;
                            {item.user && item.user.lastName ? item.user.lastName : ''} &nbsp;
                            {item.memberId || ''}
                        </Label>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div>
                            CLOVER_PRODUCTS
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            <div>
                                <div style={{ color: 'red' }}>- ${item && item.total ? parseFloat(item.total / 100).toFixed(2) : 0}</div>
                            </div>
                        }
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div>
                            <div style={{ color: 'black' }}>Clover</div>
                        </div>
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    const WalletRow = (item) => {
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        <div style={{ lineBreak: 'anywhere' }}>{item.id}</div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{ lineBreak: 'anywhere' }}>{item.refId}</div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {item.createdAt
                            ? moment(new Date(item.createdAt._seconds * 1000)).format("MM/DD/YYYY, HH:mm")
                            : ''
                        }
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <Label>{allUsers.filter(t => t.id == item.userId)[0]?.firstName} &nbsp;
                            {allUsers.filter(t => t.id == item.userId)[0]?.lastName}</Label>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>

                        <div style={{ lineBreak: 'anywhere' }}>
                            {item.type}
                            {item.desription ? item.desription : ''}
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            item.isFromCardOrBank ?
                                <div>
                                    <div style={{ color: 'red' }}>- ${item && item.amount ? parseFloat(item?.amount).toFixed(2) : 0}</div>
                                </div> :
                                <div>
                                    {
                                        item.creditType == 'Credit' ?
                                            item.points && item.points > 0 ? <div style={{ color: 'green' }}>+ ${item.points * 20}</div>
                                                :
                                                <div style={{ color: 'green' }}>+ ${item.amount}</div> :
                                            <div style={{ color: 'red' }}>- ${item && item.amount ? parseFloat(item?.amount).toFixed(2) : 0}</div>
                                    }
                                </div>
                        }
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            item.isFromCardOrBank ?
                                <div>
                                    <div style={{ color: 'black' }}>Credit Card</div>
                                </div> :
                                <div>
                                    <div style={{ color: 'black' }}>Balance</div>
                                </div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <div className={"d-flex"}>
                        <div style={{ marginTop: '8px' }}>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>MEMBER NAME</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Order Date</span>
                            <DownArowSVG />
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div>
                    </div>
                    {/* <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    /> */}
                </div>
                <div style={{ marginTop: '10px' }}>
                    {visibleTags.length !== 0 && (
                        <TagGroup style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '10px'
                        }} onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredOrderHistory.length > 0 && filteredOrderHistory.map((item) => {
                                if (item && item.collection && item.collection == 'CloverOrderHistory') {
                                    return CloverWalletRow(item)
                                } else {
                                    return WalletRow(item)
                                }
                            }
                            )}
                        </TableBody>
                        {
                            isLoading || filteredOrderHistory.length > 0 ? <div /> :
                                // <TableBody>
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Report Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please select an invoice status and date range, then click 'GENERATE REPORT' to view, export, and print the data.
                                    </div>
                                </div>
                            // </TableBody>
                        }
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && visibleTags.length < 1 && selectedOption == null && filteredOrderHistory.length > 0 && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
                {popupVisible && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <CustomSearchInput2
                            value={searchMembers}
                            onChange={handleSearchMember}
                            onClick={handleClear}
                        />
                        <div className="popupMemberList">
                            {members
                                .filter(member => member.name.toLowerCase().includes(searchMembers))
                                .map((member) => (
                                    <div key={member.id}>
                                        <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                            <div
                                                onClick={() => toggleSelect(member.id)}
                                                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                            >
                                                {member.selected ? (
                                                    <CheckBoxChecked />  // Your checked SVG
                                                ) : (
                                                    <CheckBoxUnChecked />  // Your unchecked SVG
                                                )}
                                                <div style={{ marginLeft: '8px' }}>{member.name}</div>
                                            </div>
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelMemberFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleApplyMemberFilter}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y + 12, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick() }}>EXPORT</button>
                        </div>
                    </div>
                )}

                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y + 10, left: popupPosition.x - 50 }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '47%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedStartDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div style={{ marginLeft: '3px', marginRight: '3px', marginTop: '16px', width: '18px', height: '1px', backgroundColor: '#C1C1C1' }}></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '47%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedEndDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancel}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default UserWalletHistory;

const processTransaction = (item, allUsers) => {
    // Initialize the base fields
    let transaction = {
        id: item.id || '',
        referenceId: item.externalReferenceId || item.refId || '',
        user: '',
        email: '',
        date: '',
        transactionType: '',
        amount: 0,
        type: ''
    };

    // Process CloverOrderHistory collection
    if (item.collection === 'CloverOrderHistory') {
        transaction.user = (item.user && item.user.firstName ? item.user.firstName : '') + ' ' + (item.user && item.user.lastName ? item.user.lastName : '');
        transaction.email = item.user?.email || '';
        transaction.date = moment(item.createdTime).format('MM/DD/YYYY, HH:mm');
        transaction.transactionType = 'CLOVER_PRODUCTS';
        transaction.amount = item.total ? `-${parseFloat(item.total / 100).toFixed(2)}` : 0;
        transaction.type = 'Clover'
    }
    // Process TransactionHistory collection
    else if (item.collection === 'TransactionHistory') {
        // Handle user lookup by userId (assuming `allUsers` is an array of users)
        const user = allUsers.find(user => user.id === item.userId) || {};
        transaction.user = `${user.firstName || ''} ${user.lastName || ''}`;
        transaction.email = user.email || '';
        transaction.date = item.createdAt ? moment(new Date(item.createdAt._seconds * 1000)).format('MM/DD/YYYY, HH:mm') : '';
        transaction.transactionType = item.type || '';

        // Handle amount based on different conditions (isFromCardOrBank, creditType, etc.)
        if (item.isFromCardOrBank) {
            transaction.amount = item.amount ? `-${parseFloat(item.amount).toFixed(2)}` : 0;
        } else if (item.creditType === 'Credit') {
            transaction.amount = item.points && item.points > 0 ? `+${(item.points * 20).toFixed(2)}` : `+${(item.amount)}`;
        } else {
            transaction.amount = item.amount ? `-${parseFloat(item.amount).toFixed(2)}` : 0;
        }

        transaction.type = item.isFromCardOrBank ? 'Credit Card' : 'Balance';
    }

    return transaction;
};