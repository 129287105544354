import {
  Button,
  Label,
  Table,
  TableBody,
  TableCell,
  TableCellActions,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow,
  makeStyles,
  shorthands,
  Dropdown,
  Option,
  useId,
} from "@fluentui/react-components";
import {
  DeleteFilled,
  DeleteRegular,
  EditFilled,
  EditRegular,
  WalletCreditCardFilled,
  WalletCreditCardRegular,
  bundleIcon,
  AccessTimeFilled,
  AccessTimeRegular,
  FilterRegular,
  FilterFilled,
  DocumentRegular,
  DocumentFilled
} from "@fluentui/react-icons";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import DeleteEventDialog from "./components/DeleteEventDialog";
import UserForm, { InitialUserState } from "./components/Forms/UserForm";
import UserWalletForm from "./components/Forms/UserWalletForm";
import Layout from "./components/Layout";
import { Base_Url, auth, db, trackException } from "./services/firebase";
import UserDataService from "./services/userDataService";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);
const WalletIcon = bundleIcon(WalletCreditCardFilled, WalletCreditCardRegular);
const FilterIcon = bundleIcon(FilterFilled, FilterRegular);
const DocumenIcon = bundleIcon(DocumentFilled, DocumentRegular);

const useStyles = makeStyles({
  root: {
    ...shorthands.overflow("hidden"),
    display: "flex",
    height: "480px",
    backgroundColor: "#fff",
  },

  content: {
    ...shorthands.flex(1),
    ...shorthands.padding("16px"),
    display: "block",
    ...shorthands.gridArea("main"),
  },

  breakFlex: {
    flexBasis: "100%",
    textAlign: "left",
  },

  breakFlexData: {
    flexBasis: "100%",
    ...shorthands.padding("8px"),
    textAlign: "left",
  },
});

const columns = [
  { columnKey: "id", label: "Member Id", width: '10%' },
  { columnKey: "firstName", label: "First Name", width: '16%' },
  { columnKey: "lastName", label: "Last Name", width: '16%' },
  { columnKey: "genderValue", label: "Gender", width: '10%' },
  { columnKey: "email", label: "Email", width: '22%' },
  { columnKey: "isAdmin", label: "Admin", width: '10%' },
  { columnKey: "isHonorary", label: "Honorary Member", width: '10%' },
  { columnKey: "address", label: "Address", width: '14%' },
];

function Users() {
  const dropdownId = useId("dropdown-default");
  const options = [
    { key: "Male", text: "Male" },
    { key: "Female", text: "Female" },
    { key: "prefer_not_to_say", text: "prefer not to say" },
    { key: "All", text: "All" },
  ];
  const styles = useStyles();
  const [user, loading, error] = useAuthState(auth);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isWalletFormOpen, setWalletIsFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [allusers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUser, setFilteredUser] = useState([]);

  const [genderSelection, setGenderSelection] = useState();
  const [open, setOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const openForm = (selectedItem) => {
    setIsFormOpen(true);
    setSelectedUser(selectedItem);
  };
  const [selectedGenderOption, setSelectedGenderOption] = useState();

  const handleDropdownChange = (event, item) => {
    setSelectedGenderOption(item.optionText);
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserNameAndUsers();
  }, [selectedGenderOption]);

  const openFormNew = () => {
    setIsFormOpen(true);
    let r = (Math.random() + 1).toString(36).substring(7);
    setSelectedUser(r);
  };

  const openWalletForm = (selectedItem) => {
    setWalletIsFormOpen(true);
    setSelectedUser(selectedItem);
  };

  const fetchUserNameAndUsers = async () => {
    try {
      if (!!user) {
        const userquery = query(
          collection(db, "Users"),
          where("email", "==", user?.email)
        );
        const userdoc = await getDocs(userquery);
        if (userdoc.docs.length > 0) {
          if (selectedGenderOption != undefined && selectedGenderOption != "All") {
            const usersQuery = await UserDataService.getUserByGender(selectedGenderOption);
            let userArr = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            setFilteredUser(userArr)
            setAllUsers(userArr);
          } else {
            const usersQuery = await UserDataService.getAllUser();
            let userArr = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            setFilteredUser(userArr)
            setAllUsers(userArr);
          }
        } else {
          console.log("no access");
        }
      }
    } catch (err) {
      trackException(JSON.stringify(err))
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const handleFirstNameClick = (item) => {
    navigate(`/userdetails`, { state: item });
    //navigate(`/MemberPurchaseDetails`, { state: item });
  };
  const handleMemberPurchaseClick = (item) => {
    navigate(`/MemberPurchaseDetails`, { state: item });
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserNameAndUsers();
  }, [user, loading]);

  const openDeleteForm = (selectedItem) => {
    setIsDeleteDialogOpen(true);
    setSelectedUser(selectedItem);
    // fetchUserNameAndEvents();
  };

  const DeleteUser = async () => {
    UserDataService.deleteUser(selectedUser.id);
    let config = {
      method: "delete",
      url: `${Base_Url}/api/deleteUserAuth?uuid=${selectedUser.id}`,
    };
    axios
      .request(config)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
        trackException(JSON.stringify(error))
      });

    setIsDeleteDialogOpen(false);
    fetchUserNameAndUsers();
  };

  // Debounce the handleSearch function
  const debouncedSearch = debounce(search => {
    const lowerSearch = search.toLowerCase();
    const filtered = allusers.filter(event => {
      return (
        event.firstName.toLowerCase().includes(lowerSearch) ||
        event.lastName.toLowerCase().includes(lowerSearch)
      )
    }
    );
    setFilteredUser(filtered);
  }, 300); // Adjust the debounce delay as needed

  const handleSearch = event => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };
  const handleClear = () => {
    setSearchTerm('');
    debouncedSearch('');
  };

  return (
    <Layout>
      <div className={styles.content}>
        <div className={"d-flex justify-space-between"}>
          <Button
            icon={<PlusSVG />}
            shape="circular"
            appearance="primary"
            onClick={() => openFormNew()}
            className={"primary-btn"}
          >{'New Users'.toUpperCase()}
          </Button>
          <CustomSearchInput
            value={searchTerm}
            onChange={handleSearch}
            onClick={handleClear}
          />
        </div>
        <div className={styles.breakFlexData}>
          <FilterIcon aria-label="FilterIcon" />
          <label id={dropdownId} style={{ padding: "5px" }}
          >
            Gender
          </label>
          <Dropdown
            aria-labelledby={dropdownId}
            placeholder="Select a Gender"
            onOptionSelect={handleDropdownChange}
            selectedKey={
              setSelectedGenderOption ? setSelectedGenderOption.key : undefined
            }
            options={options}
          >
            {options.map((option) => (
              <Option key={option.key} value={option.key}>
                {option.text}
              </Option>
            ))}
          </Dropdown>
        </div>
        <div className={"table-layout"}>
          <Table aria-label="Table with cell actions">
            <TableHeader className={"list-row"}>
              <TableRow>
                {columns.map((column) => (
                  <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                    <span className={"listHeaderText"}>{column.label}</span>
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHeader>
            {filteredUser.length > 0 && (
              <TableBody>
                {filteredUser.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className={"table-row-fonts__primary"} >
                      <TableCellLayout>{item.memberNumber ?  item.memberNumber : ''}</TableCellLayout>
                    </TableCell>
                    <TableCell className={"table-row-fonts__primary"} onClick={() => handleFirstNameClick(item)} >
                      <TableCellLayout>{item.firstName}</TableCellLayout>
                    </TableCell>
                    <TableCell className={"table-row-fonts__desc"}>
                      <TableCellLayout>
                        <Label title={item.lastName}>{item.lastName}</Label>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell className={"table-row-fonts__desc"}>
                      <TableCellLayout>
                        <Label title={item?.genderValue}>
                          {item?.genderValue}
                        </Label>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell className={"table-row-fonts__desc"}>
                      <TableCellLayout>
                        <Label title={item.email}>{item.email}</Label>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell className={"table-row-fonts__desc"}>{item.isAdmin ? <div className={"yesBox"}>Yes</div> : <div className={"noBox"}>No</div>}</TableCell>
                    <TableCell className={"table-row-fonts__desc"}>
                      {item.isHonorary ? <div className={"yesBox"}>Yes</div> : <div className={"noBox"}>No</div>}
                    </TableCell>
                    <TableCell className={"table-row-fonts__desc"}>
                      <TableCellLayout>
                        <Label title={item?.address?.city}>
                          {item?.address?.city}
                        </Label>
                      </TableCellLayout>
                      <TableCellActions>
                        <Button
                          icon={<DocumenIcon />}
                          appearance="subtle"
                          aria-label="Wallet"
                          onClick={() => { handleMemberPurchaseClick(item) }}
                        />
                        <Button
                          icon={<WalletIcon />}
                          appearance="subtle"
                          aria-label="Wallet"
                          onClick={() => openWalletForm(item)}
                        />
                        <Button
                          icon={<EditIcon />}
                          appearance="subtle"
                          aria-label="Edit"
                          onClick={() => openForm(item)}
                        />
                        <Button
                          icon={<DeleteIcon />}
                          appearance="subtle"
                          aria-label="Delte"
                          onClick={() => openDeleteForm(item)}
                        />
                      </TableCellActions>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </div>
      </div>
      <UserForm
        key={"userform"}
        isOpen={isFormOpen}
        setIsOpen={(val) => setIsFormOpen(val)}
        selectedUser={selectedUser}
        updateGrid={fetchUserNameAndUsers}
        allusers={allusers}
      ></UserForm>
      <UserWalletForm
        key={"UserWalletForm"}
        isOpen={isWalletFormOpen}
        setIsOpen={(val) => setWalletIsFormOpen(val)}
        selectedUser={selectedUser}
        updateGrid={fetchUserNameAndUsers}
      ></UserWalletForm>
      <DeleteEventDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        DeleteEvent={DeleteUser}
        text={"User"}
      />
    </Layout>
  );
}

export default Users;
